<template>
  <v-container>
    <v-col cols="12" lg="10" md="12" sm="12">
      <v-label>
        <h5>
          {{ locationTitle ? locationTitle : "Location" }}
          <span class="primary--text heading4" v-if="location">
            {{ location.name }}
          </span>
        </h5>
      </v-label>
    </v-col>

    <perfect-scrollbar>
      <v-row>
        <v-treeview
          :items="locations"
          @update:active="setSelected"
          :item-key="'uuid'"
          :item-text="''"
          activatable
          return-object
          color="warning"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="item.children.length">
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>

            <a class="primary--text heading5" v-if="item.children.length > 0">
              {{ item.name }}
            </a>
            <a
              class="primary--text heading5"
              v-if="item.children.length == 0"
              @click="fetch(item)"
            >
              {{ item.name }}
            </a>
            <!-- :load-children="fetchChildren" -->
          </template>
        </v-treeview>
      </v-row>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import {
  getParent as fetchLocation,
  getByChildren,
} from "../locations/services";
export default {
  name: "examTree",
  props: ["selected", "setActive", "locationTitle", "parentData", "location"],

  data() {
    return {
      search: "",
      open: [],
      active: [],
      items: [],
      children: [],
    };
  },

  methods: {
    setSelected(data) {
      this.setActive(data);
      // this.parentData = data;
    },

    fetch(data) {
      //the data from this.items

      getByChildren({
        "location.Id": data.id,
      }).then((response) => {
        const children = response.data[0].data
          .map((item) => ({
            ...item,
            type:
              item.school_type && item.school_type.code != "NOT_SCHOOL_TYPE"
                ? item.school_type.name
                : "",
            name: item.name,
            levelName: item.level.name,
            children: [],
          }))
          .map((item, index) => ({
            ...item,
            name:
              index +
              1 +
              " ." +
              item.name +
              " " +
              "(" +
              item.type +
              "  " +
              item.levelName +
              ")" +
              " ",
          }));

        data.children.push(...children);
        // this.open = data.children
        //this.active = data.children
      });
    },

    init(params) {
      fetchLocation({
        ...params,
      }).then((response) => {
        this.items = response.data[0]
          ? response.data[0].data
              .map((option) => ({
                ...option,
                school_type:
                  option.school_type &&
                  option.school_type.code != "NOT_SCHOOL_TYPE"
                    ? option.school_type.name
                    : "",
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },
  },

  mounted() {
    this.init();
  },

  computed: {
    locations() {
      return this.items
        .map((item) => ({
          id: item.id,
          type: item.school_type ? item.school_type.name : "",
          name: item.name,
          levelName: item.level.name,
          uuid: item.uuid,
          children: this.children,
        }))
        .map((item) => ({
          ...item,
          name:
            item.name +
            " " +
            "(" +
            item.type +
            "  " +
            item.levelName +
            ")" +
            " ",
        }));
    },
  },

  watch: {
    items(oldVal, newVal) {
      // this.setActive(oldVal);
    },
  },
};
</script>

<style scoped lang="scss">
.ps {
  height: 200px;
}
</style>
